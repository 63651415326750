import React from "react";
import DonationForm from "../../components/Donations/DonationForm";

const OffertoryPage = () => {
  return (
    <div>
      <DonationForm />
    </div>
  );
};

export default OffertoryPage;
