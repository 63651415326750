import React, { useEffect } from 'react';

const EbulletinDownloader = () => {
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch('https://api.kampalacentraladventist.org/api/Ebulletin/latest', {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const fileURL = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));

        // Create an anchor element and click it to open the PDF
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'ebulletin.pdf'); // You can set a default file name if desired
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Failed to download PDF', error);
      }
    };

    fetchPdf();
  }, []);

  return (
    <div>
      <p>Downloading the latest eBulletin...</p>
    </div>
  );
};

export default EbulletinDownloader;
